import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface INotification {
    id: string;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
    isSent: boolean;
}

export interface INotificationState {
    notification: INotification;


}

const initialState: INotificationState = {
    notification: {
        id: '',
        message: '',
        severity: 'success',
        isSent: false,
    },
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<INotification>) => {
            state.notification = action.payload;
        },
    },
});

export const { setMessage } = notificationSlice.actions;
export default notificationSlice.reducer;