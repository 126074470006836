import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { IUser } from '../user/IUser';
import { useEffect, useState } from 'react';
import { trim } from 'lodash';
import { setMessage } from '../slices/notificationSlice';
import { useSnackbar } from '../common/AppSnackbar';
import { isAuthenticatedAdmin } from '../components/routes/ProtectedRoutes';
import _ from 'lodash';

interface IPage {
  title: string;
  name: string;
}

// interface IHideOnScrollProps {
//   children: React.ReactElement;
// }

const { REACT_APP_MARKET_MAKER_BUY_SIDE_ENABLED, REACT_APP_APP_NAME } =
  process.env;

const settings = [
  'Wallets',
  'Bank accounts',
  'Upload Documents',
  'Profile',
  'Logout',
];

let allPages: IPage[] = [
  {
    title: 'Dashboard',
    name: 'dashboard',
  },
  {
    title: `${
      REACT_APP_MARKET_MAKER_BUY_SIDE_ENABLED === 'true' ? 'Buy & Sell' : 'Buy'
    }`,
    name: 'marketplace',
  },
  { title: 'Sell', name: 'market-maker' },
];
const Header = () => {
  const [pages, setPages] = useState<IPage[]>(allPages);



  const { showMessage } = useSnackbar();
  const notification = useSelector(
    (state: RootState) => state.notification.notification
  );
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user.user);
  const accessToken = useSelector((state: RootState) => state.user.accessToken);

  useEffect(() => {
    if (!notification?.message) return;

    if (trim(notification.message).length < 1) return;

    if (notification.isSent === false) {
      showMessage(notification.message, notification.severity);
      const sentNotification = { ...notification, isSent: true };
      dispatch(setMessage(sentNotification));
    }
  }, [notification]);

  useEffect(() => {
    const updatedPages = _.filter(pages, (p) => p.name !== 'admin');

    if (isAuthenticatedAdmin()) {
      updatedPages.push({
        title: 'Admin',
        name: 'admin',
      });
    }
    setPages(updatedPages);
  }, [accessToken]);

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onLogoClicked = () => {
    navigate('dashboard');
  };
  const onPageClicked = (page: IPage) => {
    navigate(`${page.name}/`);
  };

  const settingMenuItemClicked = (setting: string | object) => {
    setAnchorElUser(null);
    if (typeof setting === 'string')
      navigate(`/${setting.replace(/ /g, '-').toLowerCase()}`);
  };

  const getInitials = (user: IUser): string => {
    const firstName = user?.profile?.firstName;
    const lastName = user?.profile?.lastName;
    let firstChar = '';
    let lastChar = '';
    if (firstName) firstChar = firstName[0];
    if (lastName) lastChar = lastName[0];

    return `${firstChar}${lastChar}`;
  };

  // function HideOnScroll(props: IHideOnScrollProps) {
  //   const { children } = props;

  //   const trigger = useScrollTrigger();

  //   return (
  //     <Slide appear={false} direction='down' in={!trigger}>
  //       {children}
  //     </Slide>
  //   );
  // }

  return (
    // <HideOnScroll>
    <AppBar color='primary'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          {/* Desktop */}

          <Typography
            variant='h6'
            noWrap
            component='a'
            onClick={onLogoClicked}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            {REACT_APP_APP_NAME}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.title}
                onClick={() => onPageClicked(page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          {/* Mobile */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={() => onPageClicked(page)}>
                  <Typography textAlign='center'>{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* <HealthAndSafetyIcon
              sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
            /> */}

          <Typography
            variant='h6'
            noWrap
            component='a'
            onClick={onLogoClicked}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {REACT_APP_APP_NAME}
          </Typography>

          {/* Mobile & Desktop */}

          {user ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title='Settings'>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar>{`${getInitials(user)}`}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={settingMenuItemClicked}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => settingMenuItemClicked(setting)}
                  >
                    <Typography textAlign='center'>{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <></>
          )}
        </Toolbar>
      </Container>
    </AppBar>
    // </HideOnScroll>
  );
};

export default Header;
