import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IOffer } from '../offers/IOffer';
import ISetOrderDetailsFormInput from '../orders/ISetOrderDetailsFormInput';
import IOrder from '../orders/IOrder';

export interface IOrdersState {
  offer: IOffer | undefined;
  orderDetails: ISetOrderDetailsFormInput;
  order: IOrder | undefined;
  orders: IOrder[];
  selectedFiatAmount: number;
}

const initialState: IOrdersState = {
  offer: undefined,
  orderDetails: {
    amount: '',
    cryptoAmount: '',
    walletId: '',
    walletAddress: '',
  },
  order: undefined,
  orders: [],
  selectedFiatAmount: 0,
};

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOffer: (state, action: PayloadAction<IOffer>) => {
      state.offer = action.payload;
    },
    setOrderDetails: (
      state,
      action: PayloadAction<ISetOrderDetailsFormInput>
    ) => {
      state.orderDetails = action.payload;
    },
    setOrder: (state, action: PayloadAction<IOrder>) => {
      state.order = action.payload;
    },
    setSelectedFiatAmount: (state, action: PayloadAction<number>) => {
      state.selectedFiatAmount = action.payload;
    },
    setOrders: (state, action: PayloadAction<IOrder[]>) => {
      state.orders = action.payload;
    },
  },
});

export const {
  setOffer,
  setOrderDetails,
  setOrder,
  setOrders,
  setSelectedFiatAmount,
} = orderSlice.actions;
export default orderSlice.reducer;
