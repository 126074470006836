// For open replay /////////////
import Tracker from '@openreplay/tracker';

const tracker = new Tracker({
  projectKey: 'vEFjUCiMG3cCLrqNfBxY',
  __DISABLE_SECURE_MODE: false, // set this to true if you want to record sessions on localhost
  defaultInputMode: 0,
  obscureTextEmails: false,
  obscureTextNumbers: false,
  obscureInputEmails: false,
  obscureInputDates: false,
  network: {
    capturePayload: true,
    captureInIframes: true,
    failuresOnly: false,
    ignoreHeaders: false,
    sessionTokenHeader: false,
  },

  // domSanitizer: (node: Element) => {
  //   const elementClassNames = node.classList;

  //   if (elementClassNames.contains('open-replay-obscured')) {
  //     return SanitizeLevel.Obscured;
  //   }

  //   if (elementClassNames.contains('open-replay-hidden')) {
  //     return SanitizeLevel.Hidden;
  //   }

  //   return SanitizeLevel.Plain;
  // },
});

export default tracker;
