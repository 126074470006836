import { Navigate, Outlet } from 'react-router-dom';
import jwt_decode, { JwtPayload } from 'jwt-decode';

export const isAuthenticatedUser = () => {
  let isValidToken = false;
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    return false;
  }

  const { exp } = jwt_decode<JwtPayload>(accessToken);
  isValidToken = !!exp && Date.now() < (exp || 0) * 1000;
  return isValidToken;
};

export const isAuthenticatedAdmin = () => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    return false;
  }
  const { userRole } = jwt_decode<{ userRole: string }>(accessToken);
  return isAuthenticatedUser() && ['admin', 'superAdmin'].includes(userRole);
};

export const isAuthenticatedSuperAdmin = () => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    return false;
  }
  const { userRole } = jwt_decode<{ userRole: string }>(accessToken);
  return isAuthenticatedUser() && ['superAdmin'].includes(userRole);
};

export const AuthenticatedUserRoutes = () => {
  return isAuthenticatedUser() ? <Outlet /> : <Navigate to='/login' />;
};

export const AuthenticatedAdminRoutes = () => {
  return isAuthenticatedUser() && isAuthenticatedAdmin() ? (
    <Outlet />
  ) : (
    <Navigate to='/dashboard' />
  );
};
