import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IOffer } from '../offers/IOffer';

export interface IOfferBookState {
  filter: {
    side: string;
    crypto: string;
    acceptedOrderSize?: number;
  };
  offers: IOffer[];
  btcAud?: number;
  usdtAud?: number;
}

const initialState: IOfferBookState = {
  filter: {
    side: 'buy',
    crypto: 'usdt',
  },
  offers: [],
};

export const offerBookSlice = createSlice({
  name: 'offerBook',
  initialState,
  reducers: {
    setSide: (state, action: PayloadAction<string>) => {
      state.filter.side = action.payload;
    },
    setCrypto: (state, action: PayloadAction<string>) => {
      state.filter.crypto = action.payload;
    },
    setAcceptedOrderSize: (state, action: PayloadAction<number>) => {
      state.filter.acceptedOrderSize = action.payload;
    },

    setOffers: (state, action: PayloadAction<IOffer[]>) => {
      state.offers = action.payload;
    },
    setBtcAud: (state, action: PayloadAction<number>) => {
      state.btcAud = action.payload;
    },
    setUsdtAud: (state, action: PayloadAction<number>) => {
      state.usdtAud = action.payload;
    },
  },
});

export const {
  setSide,
  setCrypto,
  setAcceptedOrderSize,
  setOffers,
  setBtcAud,
  setUsdtAud,
} = offerBookSlice.actions;
export default offerBookSlice.reducer;
