import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { IOffer } from '../offers/IOffer';
import ICreateOfferFormInput from '../offers/ICreateOfferFormInput';
import dayjs from 'dayjs';

export interface IOfferState {
  offer: IOffer | undefined;
  offerDetails: ICreateOfferFormInput | undefined;
  offers: IOffer[] | undefined;
}

const initialState: IOfferState = {
  offer: undefined,
  offerDetails: undefined,
  offers: [],
};

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    setOffer: (state, action: PayloadAction<IOffer>) => {
      state.offer = action.payload;
    },

    setOfferDetails: (state, action: PayloadAction<ICreateOfferFormInput>) => {
      const closingTime = dayjs(action.payload.closingTime).toISOString();
      const openingTime = dayjs(action.payload.openingTime).toISOString();
      state.offerDetails = { ...action.payload, closingTime, openingTime };
    },
    setOffers: (state, action: PayloadAction<IOffer[]>) => {
      state.offers = action.payload;
    },
  },
});

export const { setOffer, setOfferDetails, setOffers } = offerSlice.actions;
export default offerSlice.reducer;
