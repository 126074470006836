import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../user/IUser';
import _ from 'lodash';
import { IOffer } from '../offers/IOffer';
import IOrder from '../orders/IOrder';

export interface IAdminState {
  tab: string;
  users: IUser[];
  user: IUser | null;
  offers: IOffer[];
  offer: IOffer | null;
  orders: IOrder[];
  order: IOrder | null;
  depositsOrders: IOrder[];
  transactionMonitoringSearchTerm: string | null;
}

const initialState: IAdminState = {
  tab: 'users',
  users: [],
  user: null,
  offers: [],
  offer: null,
  orders: [],
  order: null,
  depositsOrders: [],
  transactionMonitoringSearchTerm: null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<string>) => {
      state.tab = action.payload;
    },
    setUsers: (state, action: PayloadAction<IUser[]>) => {
      state.users = action.payload;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setOffers: (state, action: PayloadAction<IOffer[]>) => {
      state.offers = action.payload;
    },
    setOffer: (state, action: PayloadAction<IOffer>) => {
      state.offer = action.payload;
    },
    setOrders: (state, action: PayloadAction<IOrder[]>) => {
      state.orders = action.payload;
    },
    setOrder: (state, action: PayloadAction<IOrder>) => {
      state.order = action.payload;
    },
    setDepositsOrders: (state, action: PayloadAction<IOrder[]>) => {
      state.depositsOrders = action.payload;
    },
    setTransactionMonitoringSearchTerm: (state, action: PayloadAction<string>) => {
      state.transactionMonitoringSearchTerm = action.payload;
    },
  },
});

export const {
  setTab,
  setUsers,
  setUser,
  setOffers,
  setOffer,
  setOrders,
  setOrder,
  setDepositsOrders,
  setTransactionMonitoringSearchTerm,
} = adminSlice.actions;
export default adminSlice.reducer;
