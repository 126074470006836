import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import ICountry from '../countries/ICountry';

export interface ICountryState {
    countries: ICountry[] | undefined;
}

const initialState: ICountryState = {
    countries: undefined,
};

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountries: (state, action: PayloadAction<ICountry[]>) => {
            state.countries = action.payload;
        }
    },
});

export const { setCountries } = countrySlice.actions;
export default countrySlice.reducer;