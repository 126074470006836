import { createTheme } from '@mui/material';

const appTheme = createTheme({
  palette: {
    primary: {
      main: '#3236E9',
    },
    secondary: {
      main: '#6c57f3',
    },
    background: {
      default: '#F5F5F5',
      paper: '#F5F5F5',
    },
    warning: {
      main: '#cd8f00',
      light: '#cd8f00',
    },
  },

  typography: {
    button: {
      textTransform: 'none', // disables upper case text in a button
    },
    fontFamily: ['Poppins', 'Roboto', 'Arial', 'sans-serif'].join(','),
  },
});

export default appTheme;
