import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ICryptoRates } from '../crypto/ICryptoRates';
import { ICrypto } from '../crypto/ICrypto';

export interface ICryptoState {
    rates: ICryptoRates;
    cryptos: ICrypto[];
}

const initialState: ICryptoState = {
    rates: { btcAud: 0, usdtAud: 0, btcNzd: 0, usdtNzd: 0 },
    cryptos: [],
};

export const cryptoSlice = createSlice({
    name: 'crypto',
    initialState,
    reducers: {
        setRates: (state, action: PayloadAction<ICryptoRates>) => {
            state.rates = action.payload;
        },
        setCryptos: (state, action: PayloadAction<ICrypto[]>) => {
            state.cryptos = action.payload;
        },
    },
});

export const { setRates, setCryptos } = cryptoSlice.actions;
export default cryptoSlice.reducer;