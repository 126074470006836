import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../user/IUser';
import * as _ from 'lodash';

export interface IUserState {
  user?: IUser | null;
  users: IUser[];
  accessToken?: string | null;
}

const initialState: IUserState = {
  user: null,
  users: [],
  accessToken: null,
};

const updateOnlyFromDefinedProperties = (
  user?: IUser | null,
  other?: IUser | null
): IUser | null | undefined => {
  if (!user) return other;

  const updatedUser: IUser = { ...user };

  _.forEach(other, (value, key) => {
    if (value) {
      updatedUser[key] = value;
    }
  });
  return updatedUser;
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    updateUser: (state, action: PayloadAction<IUser>) => {
      const currentUser = state.user;
      const updatedUser = action.payload;
      state.user = updateOnlyFromDefinedProperties(currentUser, updatedUser);
    },
    setUsers: (state, action: PayloadAction<IUser[]>) => {
      state.users = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
  },
});

export const { setUser, updateUser, setUsers, setAccessToken} = userSlice.actions;
export default userSlice.reducer;
