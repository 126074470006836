import { Provider } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import App from '../../App';
import { store } from '../../store';
import { AnimatePresence } from 'framer-motion';
import { Suspense, lazy } from 'react';
import {
  AuthenticatedUserRoutes,
  AuthenticatedAdminRoutes,
} from './ProtectedRoutes';


const DashboardPage = lazy(() => import('../../dashboard/DashboardPage'));
const LoginPage = lazy(() => import('../../login/LoginPage'));
const CreateOfferPage = lazy(() => import('../../offers/CreateOfferPage'));
const MarketplacePage = lazy(() => import('../../orders/MarketplacePage'));
const SetOrderDetailsPage = lazy(
  () => import('../../orders/SetOrderDetailsPage')
);
const LogoutPage = lazy(() => import('../logout/LogoutPage'));
const RegistrationPage = lazy(
  () => import('../../registration/RegistrationPage')
);
const AddWalletPage = lazy(() => import('../../wallets/AddWalletPage'));
const WalletsPage = lazy(() => import('../../wallets/WalletsPage'));

const VerifyIdentityPage = lazy(
  () => import('../../verify-identity/VerifyIdentityPage')
);
const ConfirmOrderPage = lazy(() => import('../../orders/ConfirmOrderPage'));
const ConfirmOrderUpdatesPage = lazy(
  () => import('../../orders/ConfirmOrderUpdatesPage')
);

const BankAccountsPage = lazy(
  () => import('../../bank-account/BankAccountsPage')
);
const AddBankAccountPage = lazy(
  () => import('../../bank-account/AddBankAccountPage')
);
const PaymentPage = lazy(() => import('../../payment/PaymentPage'));
const ProfilePage = lazy(() => import('../../profile/ProfilePage'));
const EditProfilePage = lazy(() => import('../../profile/EditProfilePage'));
const UpdateCryptoTransactionPage = lazy(
  () => import('../../crypto/UpdateCryptoTransactionPage')
);
const ConfirmOfferPage = lazy(() => import('../../offers/ConfirmOfferPage'));
const UploadDocumentsPage = lazy(
  () => import('../../verify-identity/UploadDocumentsPage')
);

const AdminPage = lazy(() => import('../../admin/AdminPage'));
const VerifyEmailPage = lazy(
  () => import('../../verify-email/VerifyEmailPage')
);

const AuthorizationOfDepositPage = lazy(
  () => import('../../authorization-of-deposit/AuthorizationOfDepositPage')
);

const PaymentReferencePage = lazy(
  () => import('../../payment-reference/PaymentReferencePage')
);

const SourceOfFundsPage = lazy(
  () => import('../../source-of-funds/SourceOfFundsPage')
);

const ProofOfAddressPage = lazy(
  () => import('../../proof-of-address/ProofOfAddressPage')
);

const ResetPasswordPage = lazy(
  () => import('../../reset-password/ResetPasswordPage')
);

const SelfiePage = lazy(() => import('../../selfie/SelfiePage'));
const VideoSelfiePage = lazy(
  () => import('../../video-selfie/VideoSelfiePage')
);

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode='wait'>
      <Suspense>
        <Routes location={location} key={location.pathname}>
          <Route path='/' element={<AppWrapper />}>
            <Route element={<AuthenticatedUserRoutes />}>
              <Route path='' element={<Navigate replace to='/dashboard' />} />
              <Route path='dashboard' element={<DashboardPage />} />
              <Route path='marketplace' element={<MarketplacePage />} />
              <Route
                path='set-order-details'
                element={<SetOrderDetailsPage />}
              />
              <Route path='confirm-order' element={<ConfirmOrderPage />} />
              <Route
                path='confirm-order-updates/:orderId'
                element={<ConfirmOrderUpdatesPage />}
              />
              <Route path='payment' element={<PaymentPage />} />
              <Route path='logout' element={<LogoutPage />} />
              <Route path='verify-identity' element={<VerifyIdentityPage />} />
              <Route
                path='upload-documents'
                element={<UploadDocumentsPage />}
              />
              <Route path='verify-email' element={<VerifyEmailPage />} />
              <Route
                path='authorization-of-deposit/:orderId'
                element={<AuthorizationOfDepositPage />}
              />
              <Route
                path='payment-reference/:orderId'
                element={<PaymentReferencePage />}
              />
              <Route
                path='source-of-funds/:orderId'
                element={<SourceOfFundsPage />}
              />
              <Route path='proof-of-address' element={<ProofOfAddressPage />} />
              <Route path='selfie' element={<SelfiePage />} />
              <Route path='video-selfie/:level/:orderId' element={<VideoSelfiePage />} />
              <Route path='wallets' element={<WalletsPage />} />
              <Route path='add-wallet' element={<AddWalletPage />} />
              <Route path='bank-accounts' element={<BankAccountsPage />} />
              <Route path='add-bank-account' element={<AddBankAccountPage />} />
              <Route path='market-maker' element={<CreateOfferPage />} />
              <Route path='confirm-offer' element={<ConfirmOfferPage />} />
              <Route path='profile' element={<ProfilePage />} />
              <Route path='edit-profile' element={<EditProfilePage />} />
              <Route
                path='update-crypto-transaction/:orderId'
                element={<UpdateCryptoTransactionPage />}
              />
            </Route>

            <Route element={<AuthenticatedAdminRoutes />}>
              <Route path='admin' element={<AdminPage />} />
              <Route path='admin/:view' element={<AdminPage />} />
              <Route path='admin/:view/:param1' element={<AdminPage />} />
            </Route>

            <Route path='register' element={<RegistrationPage />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='reset-password' element={<ResetPasswordPage />} />
          </Route>
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
