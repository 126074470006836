import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IWallet } from '../wallets/IWallet';
import {
  ValidationStatus,
  ValidationStatusType,
} from '../enums/ValidationStatus';

export interface IWalletsState {
  wallets: IWallet[];
  crypto: string;
  addWallet: {
    crypto: string;
    name: string;
    address: string;
    addressValidationStatus: ValidationStatusType;
  };
}

const initialState: IWalletsState = {
  wallets: [],
  crypto: 'btc',
  addWallet: {
    crypto: 'btc',
    name: '',
    address: '',
    addressValidationStatus: ValidationStatus.None,
  },
};

export const walletsSlice = createSlice({
  name: 'wallets',
  initialState,
  reducers: {
    // setWallets: (state, action: PayloadAction<IWallet[]>) => {
    //     state.wallets = action.payload;
    // },
    setCrypto: (state, action: PayloadAction<string>) => {
      state.crypto = action.payload;
    },
    setAddWalletCrypto: (state, action: PayloadAction<string>) => {
      state.addWallet.crypto = action.payload;
    },
    setAddWalletAddressValidationStatusType: (
      state,
      action: PayloadAction<ValidationStatusType>
    ) => {
      state.addWallet.addressValidationStatus = action.payload;
    },
    setAddWalletAddress: (state, action: PayloadAction<string>) => {
      state.addWallet.address = action.payload;
    },
    setAddWalletName: (state, action: PayloadAction<string>) => {
      state.addWallet.name = action.payload;
    },
  },
});

export const {
  /*setWallets,*/ setCrypto,
  setAddWalletCrypto,
  setAddWalletAddressValidationStatusType,
  setAddWalletAddress,
  setAddWalletName,
} = walletsSlice.actions;

export default walletsSlice.reducer;
