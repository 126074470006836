import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import ICountry from '../countries/ICountry';

export interface INavigationState {
    from:string;
    to:string;
}

const initialState: INavigationState = {
    from:'',
    to:''
};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setFrom: (state, action: PayloadAction<string>) => {
            state.from = action.payload;
        },
        setTo: (state, action: PayloadAction<string>) => {
            state.to = action.payload;
        }
    },
});

export const { setFrom, setTo } = navigationSlice.actions;
export default navigationSlice.reducer;