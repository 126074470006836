import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import offerBookReducer from './slices/offerBookSlice';
import walletsReducer from './slices/walletsSlice';
import orderReducer from './slices/orderSlice';
import offerReducer from './slices/offerSlice';
import bankAccountReducer from './slices/bankAccountsSlice';
import countryReducer from './slices/countrySlice';
import navigationReducer from './slices/navigationSlice';
import cryptoReducer from './slices/cryptoSlice';
import notificationReducer from './slices/notificationSlice';
import adminReducer from './slices/adminSlice';

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    user: userReducer,
    offerBook: offerBookReducer,
    wallets: walletsReducer,
    order: orderReducer,
    offer: offerReducer,
    bankAccounts: bankAccountReducer,
    country: countryReducer,
    navigation: navigationReducer,
    crypto: cryptoReducer,
    notification: notificationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
