import '@fontsource/poppins';
import './App.css';
import Header from './header/Header';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
import { ThemeProvider } from '@emotion/react';
import appTheme from './common/app-theme';
import { SnackbarProvider } from './common/AppSnackbar';

// For open replay /////////////
import tracker from './common/openReplayTracker';
tracker.start();
// For open replay ////////////

function App() {
  //   const particlesInit = useCallback(async (engine: Engine) => {
  //     await loadFull(engine);
  // }, []);
  return (
    <ThemeProvider theme={appTheme}>
      <SnackbarProvider>
        <ConfirmProvider>
          <div className='App'>
            <CssBaseline />

            <Header></Header>

            {/* <Toolbar /> */}

            {/* <Particles options={particlesOptions as ISourceOptions} init={particlesInit}  /> */}

            <Box>
              <Outlet></Outlet>
            </Box>
          </div>
        </ConfirmProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
