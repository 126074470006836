import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IBankAccount } from '../bank-account/IBankAccount';

export interface IBankAccountsState {
    bankAccounts: IBankAccount[];
    addBankAccount: {
        bankName: string;
        accountName: string;
        routingNumber?: number;
        accountNumber?: number;
    }
}

const initialState: IBankAccountsState = {
    bankAccounts: [],
    addBankAccount: {
        bankName: '',
        accountName: '',
    }
};

export const bankAccountsSlice = createSlice({
    name: 'bankAccounts',
    initialState,
    reducers: {
        setBankAccounts: (state, action: PayloadAction<IBankAccount[]>) => {
            state.bankAccounts = action.payload;
        },

        setAddBankAccountBankName: (state, action: PayloadAction<string>) => {
            state.addBankAccount.bankName = action.payload;
        },
        setAddBankAccountAccountName: (state, action: PayloadAction<string>) => {
            state.addBankAccount.accountName = action.payload;
        },
        setAddBankAccountRoutingNumber: (state, action: PayloadAction<number>) => {
            state.addBankAccount.routingNumber = action.payload;
        },
        setAddBankAccountAccountNumber: (state, action: PayloadAction<number>) => {
            state.addBankAccount.accountNumber = action.payload;
        },
        

    },
});

export const { setBankAccounts, setAddBankAccountBankName, setAddBankAccountAccountName, setAddBankAccountRoutingNumber, setAddBankAccountAccountNumber} = bankAccountsSlice.actions;
export default bankAccountsSlice.reducer;